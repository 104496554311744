@import url('https://fonts.googleapis.com/css?family=Bree+Serif|Poppins|Source+Sans+Pro|Montserrat:400,900&display=swap');

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5em;
}

.menu-coffee- {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', 'Poppins', sans-serif !important;
  margin-bottom: 2em;
}

.menu-coffee--group-heading {
  margin: 0;
  padding-bottom: 1em;
  border-bottom: 2px solid #ccc;
}

.menu-coffee--group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
  padding: 1.5em 0;
}

.menu-coffee--item {
  display: flex;
}

.menu-coffee--item-img {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  margin-right: 1.5em;
}

.menu-coffee--item-text {
  flex-grow: 1;
}

.menu-coffee--item-heading {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.menu-coffee--item-name {
  margin-right: 1.5em;
  font-size: 16px !important;
  font-weight: bold;
}

.menu-coffee--item-desc {
  line-height: 1.6;
  font-size: 16px !important;
}

.menu-coffee--item-price {
  font-size: 16px !important;
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  .menu-coffee- {
    font-size: 16px;
  }

  .menu-coffee--group {
    grid-template-columns: repeat(2, 1fr);
  }

  .menu-coffee--item-img {
    width: 125px;
    height: 125px;
  }
}
